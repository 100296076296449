@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .sidebar-icon {
    @apply relative mx-auto mt-2 mb-2
    flex h-12 w-12 cursor-pointer items-center justify-center rounded-3xl bg-pdark
    
    py-2 text-secondary
    transition-all duration-300
    ease-linear hover:rounded-xl hover:bg-secondary
    hover:text-black;
  }

  .sidebar-tooltip {
    @apply absolute left-14 m-2 w-auto min-w-max origin-left 
    scale-0 rounded-md
    bg-gray-900 p-2
    text-xs font-bold
    text-white shadow-md transition-all duration-100;
  }

  .navbar-custom {
    @apply fixed top-0 left-0 m-0 
    flex h-16 w-screen flex-row content-center items-center bg-gray-900
    text-gray-100 shadow-green-600 drop-shadow-lg;
  }

  .navbar-item-icon {
    @apply left-8 
    cursor-pointer items-center text-xl;
  }

  .navbar-item-text {
    @apply left-8 text-2xl;
  }

  .navbar-item-tooltip {
    @apply absolute left-8 top-10 m-2 w-auto min-w-max origin-top
    scale-0 rounded-md
    bg-gray-300 p-2
    text-xs font-bold
    text-gray-600 shadow-md transition-all duration-100;
  }

  .pages-content {
    @apply flex min-h-screen w-full flex-col bg-gray-100;
  }

  .dropdown-item {
    @apply border-b-2 border-sdark bg-primary py-2 px-4 text-center text-sm text-white hover:bg-slight hover:text-black;
  }

  .table-cell {
    @apply border-collapse border border-gray-900;
  }

  .page-main {
    @apply min-h-screen w-full bg-gray-100 p-1 md:p-5;
  }

  .input-form-text {
    @apply rounded text-gray-700 shadow placeholder:text-sm placeholder:text-secondary;
  }

  .mobile-nav-component {
    @apply fixed left-0 right-0 z-10 mx-1 flex h-10 items-center justify-between rounded-full bg-plight text-base text-white;
  }

  .mobile-sidebar-main {
    @apply fixed left-0 top-0 z-20 flex h-screen w-2/3 max-w-sm flex-col bg-primary;
  }
}

/* 
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
